export enum ECategoryType {
  AirConditioner = 'air_conditioner',
  AirFilter = 'air_filter',
  CO2Generator = 'co2_generator',
  Controller = 'controller',
  DripSystem = 'drip_system',
  GrowBox = 'grow_box',
  HydroponicSystem = 'hydroponic_system',
  Lamp = 'lamp',
  Medium = 'medium',
  Nutrient = 'nutrient',
  Seed = 'seed',
  Tent = 'tent',
  VentilationFan = 'ventilation_fan',
}

export enum ELampFaza {
  veg = 0,
  flo = 1
}

export const ECategorySort = [
  ECategoryType.Seed,
  ECategoryType.Nutrient,
  ECategoryType.Lamp,
  ECategoryType.Tent,
  ECategoryType.GrowBox,
  ECategoryType.VentilationFan,
  ECategoryType.AirFilter,
  ECategoryType.AirConditioner,
  ECategoryType.Controller,
  ECategoryType.DripSystem,
  ECategoryType.HydroponicSystem,
  ECategoryType.CO2Generator
]